#experience {
    margin-top: 0;  
}

.experience__container {
    text-align: center;
    height: 100%;    
    background: var(--color-bg-experience) !important;   
    border-radius: 0 !important;  
}

.skills-component {
    background: var(--color-bg-about);    
    width: 75%;  
    height: 51%;    
    padding: 1rem;
    margin-left: 6vw;
    margin-top: 7vw;
}

.skills-container {
    margin-left: 4vw;
}

.skills {
    display: flex;
    margin-top: 2vw;
}

.left-bar, .right-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;    
    width: 40%;
    height: 10%;
    background-color: var(--color-bg);
}

.green-bar {
    background-color: var(--color-green);
    color: var(--color-bg);
}

.orange-bar {
    background-color: orange;
}

.red-bar {
    background-color: var(--color-red);
}

.violet-bar {
    background-color: var(--color-purple);
}

.bar-1 {
    margin-left: 6vw;
}

.bar-2 {
    margin-left: 3vw;
}

.bar-3 {
    margin-left: 10vw;
}

.deco-xp {
    max-width: 10%;
    margin-top: 2vw;
    margin-left: 65vw;
}



/**************************MEDIA QUERIES*******************************/

/******************************************/
/***************Media query smartphone********/

@media screen and (max-width: 600px) {
    .skills-component {
        width: 90%;              
    }
}



@media screen and (max-width: 1024px) {

    #experience {
        height: 48vh;
    }

    .deco-xp {
        display: none;
    }

    .skills-component {        
        height: 50%;        
    }
    

    .left-bar, .right-bar {          
        width: 95%;
        height: 10%; 
        font-size: 0.8rem;      
    }

    .skills-container {
        margin-left: 1vw;
    }
}

