.about__container {
    text-align: center;
    height: 100%;    
    background: var(--color-bg-about) !important;   
    border-radius: 0 !important;  
}

.deco-about {
    max-width: 0.8%;
    margin-top: 2vw;
    margin-left: 35vw;
    display: block;
}

.profil {
    margin-left: 5vw;
    display: flex;    
}

.profil-img {
    margin-left: 2vw;
    width: 259px;
    height: 242px;    
}

.card-about {
    margin-top: 4vw;
    margin-left: 4vw;
    background-color: var(--color-bg);
    width: 245px;    
    padding: 0.9rem;
    transition: var(--transition);
}

.card-about:hover {
    transform: scale(1.3);
    transition: var(--transition);
}

.card-about span {
    color: var(--color-yellow);
}

.card-about h3 {
    color: var(--color-green);
    font-size: 1.9rem;
}

.card-about p {
    text-align: left;
    margin-top: 2vw;
    height: 200px;
    margin-bottom: 0.8rem;
}

.card-about img {
    max-width: 10%;
    display: block;
    margin: auto;

}

/******************************************/
/***************Media query Tablet********/

@media screen and (max-width: 1024px) {
    #about {
        height: 100%;
    }

    .deco-about {        
        display: none;
    }

    .profil {
        flex-direction: column;
    }

    .card-about {        
        width: 80%;
        height: 38vw;        
    } 
    
    .card-about img {
        max-width: 7%;       
    }

    .card-about p {        
        height: 15vw;        
    }
}


/******************************************/
/***************Media query smartphone********/

@media screen and (max-width: 600px) {
    #about {
        height: 100%;
    }

    .deco-about {        
        display: none;
    }

    .profil {
        flex-direction: column;
    }

    .card-about {        
        width: 80%;
        height: 50%;             
    }

    .card-about img {
        max-width: 11%;       
    }

    .card-about p {        
        height: 80%;        
    }
    
}