footer {
    height: 30vh;
}

.footer__container {     
    padding: 2rem !important;
    border-radius: 0 0 0 150px !important;
}

.logo-foot {    
    max-width: 9%; 
    margin-bottom: 0.5rem;   
}

.logo-foot, .footer__container p, .mentions {
    margin-left: 25vw;
}

.footer__container span {
    font-size: 0.8rem;
    opacity: 0.5;
}

/*****************media query devices********************/
@media screen and (max-width: 1141px) { 
    .logo-foot {    
        max-width: 39%;         
    }

    .mentions {
        display: block;
        width: 50%;
    }
}